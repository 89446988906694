<template>
    <div class="panorama-wrapper" style="position: relative;">
        <v-btn small fab color="info" absolute @click="close" style="right: 10px; top: 10px;  z-index: 100">
            <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
        <div class="panorama" style="height:100%; width: 100%; overflow: hidden"></div>
    </div>
</template>

<script>

    /* eslint-disable */

    import {mapState} from "vuex";
    import {isEmpty} from "lodash";
    import {appBus} from "@/main";
    import {ON_RESIZE} from "@/events";

    export default {
        name: 'map-panorama',
        data() {
            return {
                scene: null,
                signals: null,
                listenerIds: null,
                options: {},
            }
        },
        created() {
            appBus.$on(ON_RESIZE, () => {
                this.onResize();
            });
        },
        mounted() {
            this.init();
        },
        computed: {
            ...mapState({
                userCoordinates: state => state.panorama.userCoordinates,
                coordinates: state => state.panorama.coordinates,
            }),
            angle: {
                get() {
                    return this.$store.state.panorama.angle;
                },
                set(value) {
                    this.$store.commit('panorama/setAngle', value)
                }
            },
            coordinates: {
                get() {
                    return this.$store.state.panorama.coordinates;
                },
                set(value) {
                    this.$store.commit('panorama/setCoordinates', value)
                }
            },
        },
        methods: {
            init() {
                appBus.$emit(ON_RESIZE);
                this.scene = new SMap.Pano.Scene(document.querySelector(".panorama"), this.options);
                this.signals = this.scene.getSignals();
                this.listenerIds = this.signals.addListener(window, "pano-change", this.onPanoramaChange);
                this.show(this.userCoordinates);
            },
            show(coordinates) {
                this.onResize();

                this.$store.dispatch('map/extendTo', coordinates);
                if (!isEmpty(coordinates)) {

                    let position = SMap.Coords.fromWGS84(coordinates[0], coordinates[1]);
                    SMap.Pano.getBest(position, 50).then((place) => {
                      if (this.angle)
                          place.setLookDir(this.angle);

                      this.scene.show(place);
                    }, function () {
                    });
                }
            },
            onResize() {
                if (this.scene)
                    this.scene.syncPort();
            },
            close() {
                this.$store.dispatch('panorama/close')
            },
            onPanoramaChange() {
                let coordsObject = this.scene.getMarker().getCoords();
                if (this.coordinates[0] !== coordsObject.x || this.coordinates[1] !== coordsObject.y)
                    this.coordinates = [coordsObject.x, coordsObject.y];

                let angle = Math.round(this.scene.getCamera().yaw * 180.0 / Math.PI);
                if (this.angle !== angle)
                    this.angle = angle;
            },
        },
        watch: {
            userCoordinates: function (userCoordinates) {
                this.show(userCoordinates);
            },
        },
        beforeDestroy() {
            this.signals.removeListener(this.listenerIds);
            appBus.$emit(ON_RESIZE);
        }
    }
</script>
