<template>
    <v-card dark>
        <v-app-bar>
            Filter záznamů
            <v-spacer></v-spacer>
            <v-btn @click="reset" text><v-icon left>filter_alt</v-icon>Reset</v-btn>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text>
            <div>
                <v-text-field v-model="filters['search']" label="Text"></v-text-field>
            </div>
            <div v-for="(item, key) in allowedFilters" :key="key">
                <v-select v-model="filters[key]" :items="getItems(item.items)" :label="item.name" multiple clearable></v-select>    
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

import { mapState} from "vuex";
import {map, toNumber, isNaN, orderBy} from "lodash";

export default {
    name: 'event-filters',
    data() {
        return {
            f: {},
        }
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            settings: state => state.settings,
        }),
        allowedFilters() {
          return this.settings.filters.allowed;  
        },
        filters: {
            get() {
                console.log(JSON.stringify(this.$store.state.events.filters));
                return this.$store.state.events.filters;
            },
            set(value) {
                this.$store.commit('events/SET_FILTERS', value);
            }
        },
    },
    methods: {
        close() {
            this.$emit('onClose');
        },
        getItems(items) {
            items =  map(items, (value, key) =>  {
                const numericKey = toNumber(key);
                return {
                    text: value,
                    value: isNaN(numericKey) ? key.toLowerCase() : numericKey,
                }
            });
            return orderBy(items, ['text']);
        },
        reset() {
            this.$store.commit('events/SET_FILTERS', {});
        }
    },
}

</script>
