<template>
    <div>
        <vl-layer-vector v-if="coordinates" :opacity="screenShotActive ? 0 : 1" :zIndex="100">
            <vl-source-vector>
                <vl-feature :properties="{type: 'eventJob', id: job.id, tooltip: tooltip(job), eventKey: event.uuid}"
                            :id="job.id">
                    <vl-geom-point :coordinates="coordinates"></vl-geom-point>
                </vl-feature>
                <vl-style-func :function="styleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
        <devices :opacity="screenShotActive ? 0 : 1" v-if="isCurrentEventActive && isOnTheSpot(job)" v-bind:event="event" v-bind:job="job"></devices>
        <waypoints :opacity="screenShotActive ? 0 : 1" v-if="isCurrentEventActive && !isOnTheSpot(job) && coordinates" v-bind:event="event" v-bind:job="job"
                   v-bind:coordinates="coordinates"></waypoints>
    </div>
</template>

<script>
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import Stroke from 'ol/style/Stroke'
import {mapGetters, mapState} from "vuex";
import Devices from './Devices'
import Waypoints from './Waypoints'

export default {
    props: {
        job: Object,
        event: Object,
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        Devices,
        Waypoints,
    },
    computed: {
        ...mapGetters('events', {
            activeEventId: 'getActiveEventId',
        }),
        ...mapGetters('bases', {
            getBaseCodeById: 'getBaseCodeById',
        }),
        ...mapGetters('resourceTypes', {
            resourceTypeById: 'byId',
        }),
        ...mapGetters('eventJobs', {
            tooltip: 'tooltip',
            label: 'label',
            isOnTheSpot: 'isOnTheSpot',
            eventJobOptions: 'optionsByJob',
        }),
        isCurrentEventActive() {
            return this.activeEventId === this.event.uuid;
        },
        ...mapState({
            vehicle(state) {
                if (this.job._vehicle !== undefined) {
                    return this.job._vehicle;
                }
                return state.resources.items[this.job.vehicle]
            },
        }),
        options() {
            return this.eventJobOptions(this.job);
            },
        icon() {
            if (this.resourceType.options?.icon) {
                return this.$store.state.setup.endpoints.imageProxyUrl +  process.env.VUE_APP_TEREZA_API_URL + this.resourceType.options.icon.file;
            } else {
                if (this.vehicle && this.vehicle.icon)
                    return this.$store.state.resources.icons[this.vehicle.icon];
            }
            return 'x';
        },
        resourceType() {
            return this.resourceTypeById(this.vehicle.resourceTypeId);
        },
        template() {
            if (this.vehicle.resourceTypeId === 1)
                return 1;

            return parseInt(this.resourceType.options?.resourceTemplateId);
        },
        baseCode() {
            return this.getBaseCodeById(this.vehicle.baseId);
        },
        coordinates() {
            return this.vehicle !== undefined && this.vehicle.pnt !== undefined && this.vehicle.pnt && this.vehicle.pnt.longitude && this.vehicle.pnt.latitude ? [parseFloat(this.vehicle.pnt.longitude), parseFloat(this.vehicle.pnt.latitude)] : false;
        },
    },
    methods: {
        styleFunc() {

            let label = this.label(this.job);
            let options = this.options;

            return () => {

                let scale = 0.5;
                let markerHeight = 100;

                let style = [];

                if (label.content) {
                    style.push(
                        new Style({
                            zIndex: 1,
                            text: new Text({
                                text: label.content,
                                font: 'bold 11px Arial, Verdana, Helvetica, sans-serif',
                                offsetY: -1 * ((markerHeight / 100) * 98) * scale,
                                placement: 'point',
                                textBaseline: 'bottom',
                                textAlign: 'center',
                                scale: scale * 2.5,
                                stroke: new Stroke({
                                    width: 3,
                                    color: "white",
                                }),
                                fill: new Fill({
                                    color: '#000000'
                                })
                            }),
                        })
                    );
                }

                style.push(
                    new Style({
                        zIndex: 2,
                        image: new Icon({
                            src: './assets/icons/map/marker-job-'+this.template+'.svg',
                            crossOrigin: 'anonymous',
                            anchor: [0.5, 1],
                            color: options.background,
                            scale: scale
                        }),
                    }),
                );

                if (this.icon.length === 1) {
                    style.push(
                        new Style({
                            zIndex: 3,
                            text: new Text({
                                text: '' + this.icon,
                                font: '60px tereza',
                                offsetY: -1 * ((markerHeight / 100) * 41) * scale,
                                placement: 'point',
                                textBaseline: 'middle',
                                textAlign: 'center',
                                scale: scale * 1.1,
                                fill: new Fill({
                                    color: options.content
                                })
                            })
                        })
                    );
                } else if (this.icon) {
                    style.push(
                        new Style({
                            zIndex: 4,
                            image: new Icon({
                                crossOrigin: "Anonymous",
                                src: this.icon,
                                anchor: [0.49, 1.08],
                                color: options.content,
                                scale: scale * 2,
                            }),
                        }),
                    );
                }

                style.push(
                    new Style({
                        zIndex: 3,
                        text: new Text({
                            text: '' + this.baseCode,
                            offsetY: -1 * ((markerHeight / 100) * 75) * scale,
                            placement: 'point',
                            textBaseline: 'middle',
                            textAlign: 'center',
                            scale: scale * 2.4,
                            fill: new Fill({
                                color: options.content
                            })
                        }),
                    })
                );

                return style;
            }
        }
    },
}
</script>
