import {find, forEach, filter, sortBy, isEmpty, map, includes} from "lodash";
import {stateMerge} from "../../object-merge";
import moment from "moment/moment";
import Vue from "vue";
import {formatDuration} from "@/helpers/duration";
import eventsApi from "@/api/events";

const hiddenEventJobStatusId = 20;

const getDefaultState = () => {
    return {
        items: {},
        waypointTimeouts: {},
        defaultStatus: {
            id: null,
            uid: 0,
            content: 'black',
            background: 'white',
            text: '-',
            onTheSpot: 0,
        },
        statuses: {
            14: {
                id: 14,
                uid: 0,
                content: 'red',
                background: 'white',
                destination: 'event',
                text: 'V příkazu k výjezdu',
                onTheSpot: 0,
            },
            15: {
                id: 15,
                uid: 1,
                content: 'white',
                background: 'red',
                destination: 'event',
                text: 'Na cestě',
                onTheSpot: 0,
            },
            16: {
                id: 16,
                uid: 2,
                content: 'red',
                background: 'yellow',
                destination: 'base',
                text: 'Na místě',
                onTheSpot: 1,
            },
            17: {
                id: 17,
                uid: 3,
                content: 'red',
                background: 'yellow',
                destination: 'base',
                text: 'Na místě',
                onTheSpot: 1,
            },
            18: {
                id: 18,
                uid: 4,
                content: 'red',
                background: 'yellow',
                destination: 'base',
                text: 'Na místě',
                onTheSpot: 1,
            },
            19: {
                id: 19,
                uid: 5,
                content: 'white',
                background: 'green',
                destination: 'base',
                text: 'Na cestě zpět',
                onTheSpot: 0,
            },
            20: {
                id: 20,
                uid: 6,
                content: 'grey',
                background: 'white',
                destination: 'base',
                text: 'Na základně',
                onTheSpot: 0,
            },
        },
    }
};

const state = getDefaultState();

const getters = {
    jobs: (state, getters, rootState, rootGetters) => (key) => {

        const event = rootGetters['events/getByKey'](key);
        if (!event)
            return [];

        const linkedEventIds = map(rootGetters['eventToEvent/byTargetEventId'](event.id), 'sourceEventId');

        return sortBy(filter(state.items, (job) => {
            return (job.eventUuid === key || includes(linkedEventIds, job.eventId)) && job.eventJobStatusId !== hiddenEventJobStatusId;
        }), [function (job) {
            let priority = 0;

            const base = rootGetters['bases/byId'](job.baseId)
            if (base.baseCategoryId !== 1)
                priority = priority + 20;

            switch (job.eventJobStatusId) {
                case 15: // on the way (internal 20, external 40 )
                    priority = priority + 20;
                    break;
                case 16: // on the spot (internal 10, external 30 )
                    priority = priority + 10;
                    if (getters['isOnTheSpot'](job) && job.arriveAt) {
                        priority = priority + parseFloat('0.' + moment(job.arriveAt).unix());
                    }
                    break;
                case 19: // on way back (internal 50, external 70 )
                    priority = priority + 50
                    break;
                case 20: // on base (internal 100, external 120 )
                    priority = priority + 100;
                    break;
            }
            return priority;
        }]);
    },
    optionsByJob: (state, getters, rootState, rootGetters) => (eventJob) => {
        if (eventJob.resourceTypeId) {
            const resourceType = rootGetters['resourceTypes/byId'](eventJob.resourceTypeId);
            return resourceType.options?.job?.content ? resourceType.options.job : getters['color'](eventJob.eventJobStatusId);            
        }

        if (eventJob.vehicle) {
            const resource = rootState.resources.items[eventJob.vehicle];
            const resourceType = rootGetters['resourceTypes/byId'](resource.resourceTypeId);
            return resourceType.options?.job?.content ? resourceType.options.job : getters['color'](eventJob.eventJobStatusId);
        }
        return getters['color'](eventJob.eventJobStatusId);
    },
    byEventKey: state => (key) => {
        return filter(state.items, (o) => {
            return o.eventUuid === key;
        });
    },
    byId: state => (key) => {
        return state.items[key];
    },
    tooltip: (state, getters, rootState, rootGetters) => (eventJob) => {

        let vehicle;
        let event = rootGetters['events/getByKey'](eventJob.eventUuid);
        const jobOptions = getters['optionsByJob'](eventJob);

        let content = '';
        if (eventJob._vehicle !== undefined) {
            vehicle = eventJob._vehicle;
        } else {
            vehicle = rootGetters['resources/byId'](eventJob.vehicle);
        }

        if (vehicle) {
            content += vehicle.name + (vehicle.licence ? '(' + vehicle.licence + ')' : '') + '<br />';
            const base = rootGetters['bases/byId'](vehicle.baseId);
            content += '<strong>' + base.name + '</strong><br />';
            if (!isEmpty(vehicle.pnt)) {
                let updatedAt = moment(vehicle.pnt.updatedAt);
                let diffInSeconds = moment().diff(updatedAt, 'seconds');

                content += (vehicle.pnt.speed > 0 ? vehicle.pnt.speed + ' km/h / ' : '') +
                    (vehicle.pnt.sourceName ? vehicle.pnt.sourceName + ' / ' : '') +
                    (vehicle.pnt.updatedAt ? updatedAt.format('HH:mm:ss DD.MM.YYYY') : '');

                if (diffInSeconds > 60 * 5)
                    content += "<br /><strong>Pozor zastaralé údaje o poloze!</strong>";

            } else {
                content += 'Poloha neurčena';
            }
        }

        return {
            title: 'Technika ' + rootGetters['bases/byId'](eventJob.baseId).code + ' / ' + jobOptions.text,
            content: content,
            overview: rootGetters['events/tooltipContent'](event),
        };
    },
    label: (state, getters, rootState, rootGetters) => (eventJob) => {

        let startTime, endTime = null;
        let content, icon = '';

        if (getters['isOnTheSpot'](eventJob)) {
            if (eventJob.arriveAt && eventJob.eventJobCategoryId == null) {
                startTime = moment(eventJob.arriveAt);
                endTime = moment(rootState.app.minuteDate);
                icon = 'ter-fire';
            }
        } else {
            let vehicle = rootGetters['resources/byId'](eventJob.vehicle);
            if (eventJob.eventJobStatusId === 15) {
                if (vehicle && vehicle.pnt && vehicle.pnt.eta) {
                    icon = 'forward';
                    startTime = moment();
                    endTime = moment(vehicle.pnt.eta);
                }

            } else if (eventJob.eventJobStatusId === 19) {
                if (vehicle && vehicle.pnt && vehicle.pnt.eta) {
                    icon = 'reply';
                    startTime = moment();
                    endTime = moment(vehicle.pnt.eta);
                }
            }
        }

        if (startTime && endTime) {
            let duration = moment.duration(endTime.diff(startTime));
            content = formatDuration(duration);
        }

        return {
            icon: icon,
            content: content,
        };
    },

    isOnTheSpot: (state) => (eventJob) => {
        return state.statuses[eventJob.eventJobStatusId].onTheSpot === 1;
    },
    color: state => (status) => {
        return state.statuses[status];
    },
    status: state => (status) => {
        let jobStatus = state.statuses[status];
        if (!jobStatus)
            jobStatus = state.defaultStatus;

        return jobStatus;
    },
    getByUid: state => (uid) => {
        return find(state.statuses, ['uid', parseInt(uid)]);
    },
    destinationType:
        state => (key) => {
            let job = state.items[key];
            return state.statuses[job.eventJobStatusId].destination;
        },
    extent:
        (state, getters, rootState, rootGetters) => (key) => {
            let jobs = getters.jobs(key);
            let extent = [];
            forEach(jobs, function (job) {
                if (job.vehicle !== undefined && job.vehicle) {
                    let vehicle = rootState.resources.items[job.vehicle];

                    // todo: to kiss
                    if (vehicle !== undefined) {
                        if (vehicle.pnt !== undefined && vehicle.pnt && vehicle.pnt.longitude !== undefined && vehicle.pnt.longitude > 0 && vehicle.pnt.latitude > 0)
                            extent.push([vehicle.pnt.longitude, vehicle.pnt.latitude])

                        if (rootGetters['events/isActiveByKey'](key) && getters['isOnTheSpot'](job)) {
                            let deviceExtend = rootGetters['devices/extend'](job.id);
                            if (deviceExtend)
                                extent = extent.concat(deviceExtend);
                        }
                    }
                }
            });
            return extent;
        },
};

const actions = {
    async set({commit}, items) {
        commit("MERGE_STATE", items)
    },
    insert({dispatch}, payload) {
        return new Promise((resolve) => {
            eventsApi.createEventJob(payload).then((response) => {
                if (response.eventJobs) {
                    dispatch('notifications/add', {
                        type: 'success',
                        message: 'Výjezd byl založen'
                    }, {root: true});

                    dispatch('app/setData', response, {root: true});
                    resolve(response);
                } else {
                    dispatch('notifications/add', {
                        type: 'error',
                        message: "Chyba při ukládání výjezdu: " + response
                    }, {root: true});
                }
            });
        })
    },
    update({dispatch}, payload) {
        return new Promise((resolve) => {
            return eventsApi.updateEventJob(payload.id, payload).then((response) => {
                dispatch('app/setData', response, {root: true});
                resolve(response);
            });
        });
    },
    end({dispatch}, id) {
        return new Promise((resolve) => {
            return eventsApi.endEventJob(id).then((response) => {
                dispatch('app/setData', response, {root: true});
                resolve(response);
            });
        });
    },
    showWaypoints({state, commit}, payload) {
        let job = state.items[payload.jobId];

        if (job.showWaypointsTimeout !== undefined)
            clearTimeout(job.showWaypointsTimeout);

        let timeout = setTimeout(() => {
            commit('HIDE_WAYPOINTS', {id: job.id});
        }, 1000 * 60);

        commit('SHOW_WAYPOINTS', {
            id: job.id,
            timeout: timeout,
        });

    },
};

const mutations = {
    SHOW_WAYPOINTS(state, payload) {
        Vue.set(state.items[payload.id], 'showWaypointsTimeout', payload.timeout);
    },
    HIDE_WAYPOINTS(state, payload) {
        Vue.delete(state.items[payload.id], 'showWaypointsTimeout');
    },
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'items', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
