<template>
    <v-flex d-flex class="map-mouse-position" v-show="mouseCoordinate || clickCoordinate">
        <v-card flat>
            <v-icon small class="mr-1">near_me</v-icon>
            {{ mousePosition }}
        </v-card>
        <v-card flat v-show="clickCoordinate">
            <v-btn icon small
                   v-clipboard:copy="clickPosition"
                   v-clipboard:success="onCopy"
                   v-clipboard:error="onError">
                <v-icon small>mouse</v-icon>
            </v-btn>
            {{ clickPosition }}
        </v-card>
    </v-flex>
</template>
<script>

    import {format} from 'ol/coordinate'; // toStringXY

    export default {
        name: 'map-position-component',
        props: {
            mouseCoordinate: [Array, Boolean],
            clickCoordinate: [Array, Boolean],
        },
        data() {
            return {
                template: '{y}, {x}'
            }
        },
        computed: {
            mousePosition() {
                return format(this.mouseCoordinate, this.template, 6);
            },
            clickPosition() {
                return format(this.clickCoordinate, this.template, 6);
            },
        },
        methods: {
            onCopy() {
                this.$noty.success("Souřadnice zkopírovány do paměti");
            },
            onError() {
                this.$noty.warning("Chyba pri kopírování");
            }
        },
    }
</script>
