<template>
    <v-layout fill-height column class="event-list fill-sidebar">
        <div class="grey darken-3 d-flex flex-grow-0 justify-center">
            <v-chip-group
                    v-model="filter"
                    column
                    mandatory
                    color="white"
                    class="px-2"
            >
                <v-tooltip bottom v-for="(item, key) in filterItems" :key="key">
                    <template v-slot:activator="{ on }">
                        <v-chip dark filter outlined  :value="key" v-on="on">{{
                                item.name
                            }}
                        </v-chip>
                    </template>
                    {{ item.tooltip}}
                </v-tooltip>
            </v-chip-group>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-switch
                                class="mt-2 mb-2"
                                inset
                                dark
                                hide-details
                                color="red"
                                v-model="onlyActive"
                                label="Kalamita"
                        ></v-switch>
                    </div>
                </template>
                Skryje neuzavřené události na kterých již neprobíhá činnost
            </v-tooltip>
        </div>
        <v-list class=" d-flex flex-column overflow-auto">
            <template v-for="(event) in events">
                <event-head :key="event.uuid" @onSelect="setActiveEvent(event.uuid)"
                            v-bind:eventKey="event.uuid"></event-head>
            </template>
        </v-list>
    </v-layout>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import eventHead from "./event/Head";

export default {
    name: 'events',
    components: {
        eventHead,
    },
    props: {
        events: {
            type: Array,
        },
    },
    methods: {
        ...mapActions('events', [
            'setActiveEvent',
        ]),
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            filterItems: state => state.settings.filters.predefined,
            isMobile: state => state.isMobile,
        }),
        ...mapGetters('settings', {
            defaultFilterKey: 'getDefaultFilterKey',
        }),
        filter: {
            get() {
                return this.$store.state.events.filter;
            },
            set(value) {
                if (value === 'all' || !value) {
                    this.$store.commit('events/SET_FILTERS', {})
                } else {
                    if (this.filterItems[value])
                        this.$store.commit('events/SET_FILTERS', this.filterItems[value].filter)
                }
            }
        },
        onlyActive: {
            get() {
                return this.$store.state.events.onlyActive;
            },
            set(value) {
                this.$store.commit('events/setOnlyActive', value)
            }
        },
    },
    watch: {
        defaultFilterKey(value) {
            if (!this.$store.state.events.filter)
                this.$store.commit("events/SET_FILTER", value);
        }
    }
}
</script>
