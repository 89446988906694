<template>
  <v-tooltip v-if="vehicle" left content-class="map-tooltip-wrapper">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="event-job d-flex" @click="onClick()">
        <div class="event-job-icon" :class="'category-'+template" :style="{color: options.content, backgroundColor: options.background}">
            {{ getBaseCodeById(vehicle.baseId) }}<br/>
            <div v-if="iconType === 1" :style="{maskImage: 'url(' + icon + ')', backgroundColor: options.content}"></div>
            <v-icon v-if="iconType === 0" :color="color.content" size="30" left>{{ icon }}</v-icon>
        </div>
        <div grow class="event-job-content" v-if="!isMobile">
            <div>{{ vehicle.name }}</div>
            <span>{{ label.content }}</span>
        </div>
      </div>
    </template>
    <tooltip-content :tooltip="tooltip(job)"></tooltip-content>
  </v-tooltip>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import TooltipContent from "@/components/ui/TooltipContent";

    export default {
      name: 'event-head-job',
      components: {TooltipContent},
      props: {
            job: Object,
        },
        computed: {
            ...mapGetters('bases', {
                getBaseCodeById: 'getBaseCodeById',
            }),
            ...mapGetters('messages', {
                unvisitedByEvent: 'unvisitedByEvent',
            }),
            ...mapGetters('eventTypes', {
                getEventType: 'get',
            }),
            ...mapGetters('resourceTypes', {
                resourceTypeById: 'byId',
            }),
            ...mapGetters('eventJobs', {
                getLabel: 'label',
                tooltip: 'tooltip',
                eventJobOptions: 'optionsByJob',
            }),
            iconType() {
                if (this.resourceType.options?.icon) {
                    return 1;
                } else {
                    return 0;
                }
            },
            icon() {
                if (this.resourceType.options?.icon) {
                    return this.$store.state.setup.endpoints.imageProxyUrl +  process.env.VUE_APP_TEREZA_API_URL + this.resourceType.options.icon.file;
                } else {
                    if (this.vehicle && this.vehicle.icon)
                        return '$vuetify.icons.vehicle_' + this.vehicle.icon;
                }
                return '$vuetify.icons.vehicle_missing';
            },
            template() {
                if (this.vehicle.resourceTypeId === 1)
                    return 1;

                return parseInt(this.resourceType.options?.resourceTemplateId);
            },
            resourceType() {
                return this.resourceTypeById(this.vehicle.resourceTypeId);
            },
            label() {
                return this.getLabel(this.job);
            },
            ...mapState({
                vehicle(state) {
                    if (this.job.vehicle)
                        return state.resources.items[this.job.vehicle];
                    return null;
                },
                isMobile: state => state.isMobile,
            }),
            options() {
                return this.eventJobOptions(this.job);
            },
        },
        methods: {
            onClick() {
                this.$emit('onJobClick', this.job);
            }
        }
    }
</script>
