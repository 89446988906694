<template>
    <div class="message-tags py-2">
        <message-tag v-for="(tag) in tags(message)"
                     :key="tag.id"
                     v-bind:tag="tag"
                     @onTagClick="tagClick"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import api from "@/api/api";
import MessageTag from "@/components/panel/sidebar/event/MessageTag";
import {appBus} from "@/main";
import {ON_CONTEXT_CLOSE, ON_FORWARD_MESSAGE} from "@/events";
import stopaApi from "@/api/stopa";
import {isEmpty} from "lodash";

export default {
    name: 'message-tags-context-menu',
    components: {MessageTag},
    props: [
        'message',
    ],
    data() {
        return {
            messageFormDialog: true,
        }
    },
    computed: {
        ...mapGetters('messages', [
            'tags',
        ]),
    },
    methods: {
        async tagClick(tag, action) {
            if (action === 1) {

                const regex = /FWD_(\d+)/gm;
                const forwardTag = regex.exec(tag.code);
                if (!isEmpty(forwardTag)) {
                    const eventCategoryId = forwardTag[1];
                    appBus.$emit(ON_FORWARD_MESSAGE, this.message, this.$store.state.messages.categories[eventCategoryId], tag);
                    appBus.$emit(ON_CONTEXT_CLOSE);
                    return;
                }

                // Send message to isv
                if (tag.code === 'ISV') {
                    this.sendToSystem(tag);
                    return;
                }

                // Confirm message
                if (tag.code === 'CONF') {
                    this.$emit('onProcessing', true);
                    await this.$store.dispatch('messages/confirm', this.message.id)
                    this.$emit('onProcessing', false);
                    return;
                }

                this.applyTag(tag);
            }
            if (action === 0) {
                this.removeTag(tag);
            }
        },
        applyTag(tag) {
            this.$emit('onProcessing', true);
            return api.post('/v2/messages/' + this.message.id + '/tag/' + tag.id)
                .then((response) => {
                    this.$store.dispatch("notifications/add", {type: 'success', message: 'Požadavek odeslán'}, {root: true});
                    this.$store.dispatch('messages/set', response);
                })
                .catch((error) => {
                    this.$store.dispatch("notifications/add", {type: 'error', message: error}, {root: true});
                })
                .finally(() => {
                    this.$emit('onProcessing', false);
                })
        },
        removeTag(tag) {
            this.$emit('onProcessing', true);
            return api.delete('/v2/messages/' + this.message.id + '/tag/' + tag.id)
                .then((response) => {
                    this.$store.dispatch("notifications/add", {type: 'success', message: 'Požadavek odeslán'}, {root: true});
                    this.$store.dispatch('messages/set', response);
                })
                .catch((error) => {
                    this.$store.dispatch("notifications/add", {type: 'error', message: error}, {root: true});
                })
                .finally(() => {
                    this.$emit('onProcessing', false);
                })
        },
        sendToSystem(tag) {
            this.sendingToSystem = true;

            let formData = new FormData();
            formData.append('id', this.message.id);
            formData.append('unit_id', this.$store.getters['bases/getUserBase'].uid);

            this.$emit('onProcessing', false);

            return stopaApi.post('/ktc/panel/sendMessage', formData)
                .then((response) => {
                    if (response.status === 1) {
                        this.$store.dispatch("notifications/add", {type: 'success', message: response.msg});
                        this.applyTag(tag)
                    } else {
                        this.$store.dispatch("notifications/add", {type: 'error', message: response.msg});
                    }
                })
                .catch((error) => {
                    this.$store.dispatch("notifications/add", {type: 'error', message: error});
                }).finally(() => {
                    this.$emit('onProcessing', false);
                });
        },
    },
}
</script>
