<template>
    <vl-layer-group v-if="event !== undefined" :opacity="opacity" :zIndex="102" :visible="visible">
        <job :screenShotActive="screenShotActive" v-for="(job, i) in jobs" :key="i" v-bind:job="job" v-bind:event="event"></job>
        <layer-event-target :screenShotActive="screenShotActive" v-bind:event="event"></layer-event-target>
        <event-destination :screenShotActive="screenShotActive" v-bind:event="event"></event-destination>
        <vl-layer-group v-if="isCurrentEventActive" :zIndex="105">
            <layer-event-geo v-bind:event="event"></layer-event-geo>
            <layer-event-poises :screenShotActive="screenShotActive" v-bind:event="event"></layer-event-poises>
            <layer-event-messages :opacity="screenShotActive ? 0 : 1" v-bind:event="event"></layer-event-messages>
            <layer-link-event :opacity="screenShotActive ? 0 : 1" v-if="showLinkEventLayer" v-bind:event="event"
                              v-bind:target-coordinates="targetCoordinates"></layer-link-event>
        </vl-layer-group>
    </vl-layer-group>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import Job from './Job'
import {includes} from "lodash";
import EventDestination from '../../../eventDestination/MapLayer'
import LayerLinkEvent from '../../../linkEvent/MapLayer'
import LayerEventPoises from '../EventPoises'
import LayerEventGeo from '../../../geo/Geo'
import LayerEventMessages from './Messages'
import LayerEventTarget from "@/components/map/layers/event/Target.vue";

export default {
    name: 'layer-events',
    components: {
        LayerEventTarget,
        Job,
        LayerEventGeo,
        LayerEventPoises,
        LayerEventMessages,
        EventDestination,
        LayerLinkEvent,
    },
    props: {
        event: Object,
        uuids: Array,
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters('events', {
            isActiveEvent: 'isActiveEvent',
            activeEventId: 'getActiveEventId',
            targetTooltip: 'targetTooltip',
        }),
        visible() {
            return includes(this.uuids, this.event.uuid);
        },
        jobs() {
            return this.$store.getters['eventJobs/jobs'](this.event.uuid)
        },
        eventCategory() {
            return this.$store.getters['eventCategories/getEventCategoryById'](this.event.eventCategoryId)
        },
        opacity() {
            return this.isActiveEvent ? this.isCurrentEventActive ? 1 : 0.2 : 1;
        },
        isCurrentEventActive() {
            return this.activeEventId === this.event.uuid;
        },
        showLinkEventLayer() {
            return this.eventCategory.type === 'call' && this.targetCoordinates;
        },
        targetCoordinates() {
            return this.event.targetLongitude && this.event.targetLatitude ? [parseFloat(this.event.targetLongitude), parseFloat(this.event.targetLatitude)] : false;
        },
    },
    methods: {
        ...mapActions({
            setTrains: 'trainLocation/setTrains',
            updateTrains: 'trainLocation/updateTrains',
        })
    },
    mounted() {
        // show train information if train defined
        if (this.event !== undefined
            && this.event.data !== null
            && this.event.data !== undefined
            && this.event.data.trainNumber !== undefined
            && this.event.data.trainNumber != 0
        ) {
            this.setTrains(null, this.event.data.trainNumber);
            setInterval(function () {
                this.updateTrains();
            }.bind(this), 30000);
        }
    },
}
</script>
