<template>
    <div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {appBus} from "@/main";
import {ON_MEDIA_SHOW} from "@/events";
import {api as viewerApi} from 'v-viewer'
import {forEach} from "lodash";
import app from "@/api/app";

export default {
    name: "ImageViewer",
    props: {
        viewerOptions: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    created() {
        appBus.$on(ON_MEDIA_SHOW, (media, eventId, messageCategoryId) => {
            this.media = media;
            this.eventId = eventId;
            this.messageCategoryId = messageCategoryId;
            this.openImageViewer();
        });
    },
    data() {
        return {
            viewer: null,
            media: null,
            eventId: null,
            messageCategoryId: null,
            options: {
                url: 'data-src',
                container: '.v-application',
                title: true,
                show: () => {
                    if (this.viewer) {
                        const footer = this.viewer.footer;
                        const buttonElement = footer.querySelector('.viewer-coords');
                        if (buttonElement) {
                            buttonElement.textContent = '...';
                        }
                    }
                },
                view: (e) => {
                    const footer = this.viewer.footer;
                    const messageId = e.detail.originalImage.getAttribute('data-message-id');
                    const message = this.$store.getters['messages/byId'](messageId);
                    const buttonElement = footer.querySelector('.viewer-coords');

                    if (buttonElement) {
                        if (message.latitude && message.latitude) {
                            buttonElement.textContent = message.latitude + ', ' + message.longitude;
                        } else {
                            buttonElement.textContent = 'bez souřadnic';
                        }
                    }
                },
                viewed: (e) => {
                    const imgElement = e.detail.originalImage;
                    const messageId = imgElement.getAttribute('data-message-id');
                    this.readMessage(messageId);
                },
                toolbar: {
                    zoomIn: true,
                    zoomOut: true,
                    reset: true,
                    oneToOne: true,
                    play: false,
                    prev: true,
                    next: true,
                    rotateLeft: true,
                    rotateRight: true,
                    flipHorizontal: true,
                    flipVertical: true,
                    download: {
                        click: async () => {
                            const viewer = this.viewer
                            const response = await axios({
                                method: 'GET',
                                url: viewer.image.src,
                                responseType: 'blob', // Important
                            });
                            let blob = new Blob([response.data], {type: response.headers['content-type']});
                            let url = window.URL.createObjectURL(blob);
                            let link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', viewer.image.alt);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    },
                    coords: {
                        click: () => {
                            const footer = this.viewer.footer;
                            const buttonElement = footer.querySelector('.viewer-coords');
                            if (buttonElement) {
                                let text = buttonElement.textContent;
                                navigator.clipboard.writeText(text).then(() => {
                                    this.$noty.success("Souřadnice zkopírovány do paměti");
                                }, (err) => {
                                    this.$noty.error("Chyba kopírování do paměti:" + err);
                                });
                            }
                        }
                    },
                }
            },
        }
    },
    computed: {
        mergedOptions() {
            return Object.assign({}, this.options, this.viewerOptions);
        },
        ...mapGetters('messages', {
            mediaByEventIdAndMessageCategoryId: 'mediaByEventIdAndMessageCategoryId',
        }),
        imageObjects() {
            const imageObjects = [];
            forEach(this.mediaByEventIdAndMessageCategoryId(this.eventId, this.messageCategoryId), (message) => {
                forEach(message.media, (media) => {
                    imageObjects.push({
                        'data-src': this.getMediaUrl(media, ''),
                        src: this.getMediaUrl(media, 'small'),
                        alt: 'image',
                        'data-media': media,
                        'data-event': this.eventId,
                        'data-message-id': message.id,
                    })
                });
            });
            return imageObjects;
        }
    },
    methods: {
        getMediaUrl(media, size) {
            return app.getMediaUrl(this.eventId, media, size)
        },
        openImageViewer() {
            let options = {
                options: this.mergedOptions,
                images: this.imageObjects,
            };

            const index = this.imageObjects.findIndex(image => image['data-media'] === this.media);
            if(index !== -1) {
                options.options.initialViewIndex = index;
            }
            this.viewer = viewerApi(options);
        },
        ...mapActions('messages',
            {
                readMessage: 'read',
            }
        ),
    },
    beforeDestroy() {
        appBus.$off(ON_MEDIA_SHOW);
    }
}
</script>
