<template>
    <!--  <v-sheet min-height="50" class="fill-height" color="transparent">-->
    <!--    <v-lazy :options="{ threshold: .5 }" class="fill-height">-->
    <div class="v-message" v-bind:class="classes">
        <div v-if="showHeader" class="sender">
            <v-btn depressed fab x-small>
                <v-icon>$vuetify.icons.chatUser</v-icon>
            </v-btn>
            <span>{{ deviceCode }}-{{ senderName }}</span>
        </div>
        <div :class="rowClasses">
            <v-flex :order-xs2="!isMy" grow xs2>
                <div class="justify-space-between header">
                    <span class="dates">{{ smartDate }}</span>
                </div>
            </v-flex>
            <v-flex :order-xs1="!isMy" shrink>
                <v-row align-end class="bubble" justify-space-between no-gutters
                       @click="onClick"
                       @dblclick.prevent.stop="onDoubleClick"
                       @contextmenu.stop.prevent="onContextMenu"
                >
                    <v-col class="content flex-grow-1">
                        <div class="text"><small class="sequence">({{ sequence }})</small> <span v-linkified
                                                                                                 v-html="content"></span>
                        </div>
                        <div v-if="media" class="d-flex">
                            <media v-for="(item, index) in media"
                                   :id="id"
                                   :key="index"
                                   :autoplay="autoplay"
                                   :eventId="eventId"
                                   :messageId="id"
                                   :media="item"
                                   :message-category-id="category.id"
                                   :messageType="type"
                                   @onVisit="onVisit"/>
                            <image-comparison v-if="type === 'X50' && media.length === 2"
                                              class="mt-2 ml-2"
                                              fab
                                              plain
                                              small
                                              v-bind:after="media[1]"
                                              v-bind:before="media[0]"
                                              v-bind:event="event">
                                <v-icon>compare</v-icon>
                            </image-comparison>
                        </div>
                        <span v-if="!single" class="tags"><message-tag-dots v-bind:message="message"></message-tag-dots></span>
                    </v-col>
                </v-row>
            </v-flex>
        </div>
    </div>
    <!--    </v-lazy>-->
    <!--  </v-sheet>-->
</template>

<script>

import {faCheck as confirm,} from '@fortawesome/free-solid-svg-icons'
import media from './Media'
import moment from "moment/moment";
import {mapActions, mapState} from "vuex";
import stopaApi from "@/api/stopa";
import {appBus} from "@/main";
import {ON_CONTEXT_OPEN} from "@/events";
import MessageTagDots from "@/components/panel/sidebar/event/MessageTagDots";
import ImageComparison from "@/components/ui/ImageComparison.vue";

export default {
    inject: ['theme'],
    name: 'sidebar-event-message',
    components: {
        ImageComparison,
        MessageTagDots,
        media,
    },
    created() {
        if (this.isMy || this.type !== 'X11') {
            this.autoplay = false;
        } else {
            const createdAtMoment = moment(this.createdAt, "YYYY-MM-DD hh:mm:ss");
            const duration = moment.duration(moment().diff(createdAtMoment));
            this.autoplay = this.isNew && duration.asMinutes() < 5;
        }
    },
    props: {
        id: Number,
        isNew: Boolean,
        eventId: Number,
        content: String,
        type: String,
        userId: Number,
        deviceId: Number,
        deviceCode: String,
        senderName: String,
        sequence: Number,
        createdAt: String,
        insertedAt: String,
        media: Array,
        message: Object,
        messages: Array,
        unvisited: Array,
        read: Number,
        previous: Object,
        next: Object,
        category: Object,
        single: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        smartDate() {
            let m = moment(this.createdAt);

            if (m.isSame(moment(), 'day')) {
                return moment(this.createdAt).format('HH:mm')
            } else if (m.isSame(moment(), 'week')) {
                return moment(this.createdAt).format('dd HH:mm')
            } else {
                return moment(this.createdAt).format('DD.MM HH:mm')
            }
        },
        order() {
            return 1;
        },
        showHeader() {
            return !this.prevIsMy;
        },
        prevIsMy() {
            return this.previous && this.previous.deviceId === this.deviceId && this.previous.userId === this.userId;
        },
        nextIsMy() {
            return this.next && this.next.deviceId === this.deviceId && this.next.userId === this.userId;
        },
        visited() {
            return this.read;
        },
        ...mapState({
            event: state => state.events.local[state.events.active],
        }),
        canConfirm() {
            return !this.isMy;
        },
        isMy() {
            return this.$store.getters['messages/isMy'](this.id);
        },
        classes() {
            return {
                'unvisited': !this.read,
                'self': this.isMy,
                'other': !this.isMy,
                'prev': this.prevIsMy,
                'next': this.nextIsMy,
                'theme--dark': this.theme.isDark,
                'theme--light': !this.theme.isDark
            }
        },
        rowClasses() {
            return {
                'd-flex': true,
                'no-gutters': true,
                'justify-start': !this.isMy,
                'justify-end': this.isMy,
            }
        }
    },
    data() {
        return {
            autoplay: false,
            submitting: false,
            sendingToSystem: false,
            hover: false,
            icons: {
                confirm,
            },
            delay: 200,
            clicks: 0,
            timer: null,
        }
    },
    methods: {
        onClick(event) {
            if (this.single === true)
                return;

            this.clicks++;
            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0;
                    this.onSingleClick(event);
                }, this.delay);
            } else {
                clearTimeout(this.timer);
                this.clicks = 0;
            }
        },
        onVisit() {
            this.readMessage(this.id);
        },
        onSingleClick(event) {

            if (this.single === true)
                return;

            if (window.getSelection().toString())
                return;

            if (event.target.tagName !== 'IMG' && event.target.tagName !== 'AUDIO') {
                this.readMessage(this.id);
            }
        },
        onDoubleClick() {

            if (this.single === true)
                return;

            this.clearSelection();
            this.readMessage(this.id);

            if (this.canConfirm)
                this.confirm();
        },
        onContextMenu(event) {
            if (!this.single)
                appBus.$emit(ON_CONTEXT_OPEN, event, {props: this.$props, data: this.$data});
        },
        sendToSystem() {

            this.sendingToSystem = true;

            let formData = new FormData();
            formData.append('id', this.id);
            formData.append('unit_id', this.$store.getters['bases/getUserBase'].uid);

            return stopaApi.post('/ktc/panel/sendMessage', formData)
                .then((response) => {
                    if (response.status === 1) {
                        this.$store.dispatch("notifications/add", {type: 'success', message: response.msg});
                    } else {
                        this.$store.dispatch("notifications/add", {type: 'error', message: response.msg});
                    }
                })
                .catch((error) => {
                    this.$store.dispatch("notifications/add", {type: 'error', message: error});
                }).finally(() => {
                    this.sendingToSystem = false;
                });
        },
        clearSelection() {
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            } else if (document.selection) {
                document.selection.empty();
            }
        },
        ...mapActions('messages',
            {
                readMessage: 'read',
            }
        ),
        confirm() {
            this.$store.dispatch('messages/confirm', this.id);
        }
    }
}
</script>
