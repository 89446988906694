import {AbilityBuilder, Ability} from '@casl/ability';
import {includes} from "lodash";

export default (store) => {
    const ability = store.getters.ability

    ability.update(store.state.rules)

    return store.subscribe((mutation) => {

        const {can, rules} = new AbilityBuilder(Ability);

        switch (mutation.type) {
            case 'auth/setIdentity':
                if (mutation.payload !== undefined) {
                    if (includes(mutation.payload.roles, 'admin')) {
                        can('manage', 'Debug');
                    }
                    
                    if (includes(mutation.payload.roles, 'udrp')) {
                        can('view', 'EventContact');
                        can('manage', 'Message');
                        can('manage', 'Message3');
                    } else {
                        can('manage', 'Device');
                        can('manage', 'Event');
                        can('manage', 'Message');
                        can('manage', 'EventResources');
                        can('manage', 'EventContact');
                        can('view', 'EventChild');

                        can('manage', 'Message2');
                        can('manage', 'Message3');
                    }

                    can('manage', 'Message5');
                    
                    ability.update(rules)
                }
                break
            case 'auth/logout':
                ability.update([{actions: 'read', subject: 'all'}])
                break
        }
    })
}
