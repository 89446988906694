<template>
    <div>
        <event v-for="event in events" :key="event.uuid" v-bind:event="event"
               :screenShotActive="screenShotActive"  v-bind:uuids="filteredEventsUuids" ></event>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {map} from "lodash";
    import Event from './event/Event'

export default {
    name: 'layer-events',
    components: {
        Event,
    },
    props: {
        screenShotActive: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        events() {
            console.log(this.recentEvents);
            if (this.isMobile && this.isActiveEvent)
                return {activeEventUuid: this.activeEvent};

            return this.recentEvents;
        },
        filteredEventsUuids() {
            return map(this.filteredEvents, 'uuid');
        },
        ...mapGetters('events', {
            filteredEvents: 'getFilteredEvents',
            recentEvents: 'getRecentEvents',
            isActiveEvent: 'isActiveEvent',
            activeEvent: 'getActiveEvent',
        }),
        ...mapState({
            isMobile: state => state.isMobile,
            activeEventUuid: state => state.events.active,
        }),
    },
}
</script>
