<template>
    <v-chip :color="tag.color" label :light="darkMode" class="mr-2 mb-2" v-on="canClick ? { click: () => toggleTag() } : null">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                    <span v-on="on">
                        {{ tag.name }}
                          <v-avatar right v-show="tag.state === 1">
                            <v-icon>{{ icon }}</v-icon>
                         </v-avatar>
                    </span>
            </template>
            {{ tooltip }}
        </v-tooltip>
    </v-chip>
</template>

<script>

export default {
    name: 'message-tag',
    props: [
        'tag',
    ],
    computed: {
        tooltip() {
            return this.tag.state === 1 ? this.tag.tooltipActive: this.tag.tooltipInactive;
        },
        canClick() {
            if (this.tag.state === 0)
                return true;

            return this.tag.state === 1 && this.tag?.options?.canRemove === 1;
        },
        icon() {
            return this.tag.state ? 'done' : 'done'
        },
        darkMode() {
            return this.tag.colorText === 'dark';
        },
        colorText() {
            return this.tag.colorText === 'light' ? 'white' : 'black';
        },
    },
    methods: {
        toggleTag() {
            this.$emit('onTagClick', this.tag, this.tag.state === 1 ? 0 : 1);
            return false;
        },
    },
}
</script>
